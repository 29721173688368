@import 'overrides/overrides';
@import '../../aveda_base/scss/aveda-base-pc';
@import '../../core_base/scss/core_base_pc';
@import 'me_fonts';
@import '_all.scss';
/* Styles added for Email sighup, Contact us & Unsubscribe page */

#email-signup,
#contact-us {
  max-width: 980px;
  margin: 15px auto;
}

.form-error.label-error {
  margin-bottom: 25px;
}

.email-signup__content {
  .form-item.form-type-textfield {
    float: left;
    width: 50%;
  }
  .form-item.form-type-textfield .form-text {
    width: 90%;
  }
  .form-item.form-item-form-EMAIL-PROMOTIONS {
    clear: both;
  }
}

.cs-page__content {
  float: none;
  max-width: 960px;
  margin: 0 auto;
  .cs-page__content-item .form-text {
    display: block;
  }
  .form-type-textfield {
    float: left;
    width: 50%;
    padding-right: 20px;
  }
  .form-type-textfield input {
    width: 100%;
  }
  .form-item-form-EMAIL-ADDRESS,
  .form-item-form-EMAIL-ADDRESS input {
    width: 100%;
  }
}

#contact-us .email_format.form-text {
  display: block;
}

.page-utilities__signup {
  input[type='email'] {
    width: 100%;
    margin-bottom: 10px;
  }
  input[type='submit'],
  input[type='button'],
  button {
    width: 100%;
  }
}

.email-signup__content {
  .form-error.label-error {
    height: 20px;
    overflow: hidden;
  }
}

.contact-us__content {
  .form-error.label-error {
    height: 20px;
    overflow: hidden;
  }
  .grippie {
    display: none;
  }
  .form-item-form-FIRST-NAME {
    padding-right: 20px;
  }
}

#contact-us-thanks-page {
  .contact-us-thanks-header__title {
    margin-top: 5%;
  }
  .float-left.submit-button {
    margin-bottom: 5%;
  }
}

.form-item-form-EMAIL-PROMOTIONS-1 span.form-required {
  display: none;
}

.form-item-form-AGREE-POLICY span.form-required {
  display: none;
}

.site-email-signup__success--welcome-15 {
  .form-text.offers-email-signup-form__email {
    float: left;
    width: 246px;
  }
  .form-submit {
    width: 246px;
    float: left;
    margin-top: 15px;
    background: transparent none repeat scroll 0 0;
    color: #fff;
    border: 1px solid #fff;
    margin-bottom: 8px;
  }
}

.node-19416.node-elc-form {
  .form-type-checkboxes {
    margin-bottom: 10px;
  }
}

.welcome-15 {
  .offers-email-signup-form__errors-first.error.error_messages {
    color: red;
    font-size: 12px;
    max-width: 270px;
    text-align: left;
  }
  .site-email-signup__success-header {
    font-size: 33px;
  }
  .site-email-signup__success {
    padding: 0.5em 0 0 2.5em;
  }
  .site-email-signup__success-offer {
    font-size: 21px;
  }
}

.language-picker-v1.language-picker .language {
  padding-top: 3px;
}

.site-footer__connect {
  .menu__item--lvl-2 {
    &:lang(ar-ME) {
      margin-right: 0;
      margin-left: 18px;
    }
  }
}
